import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ChartConfiguration, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { UrlDetails } from '../../interfaces/IUrlDetails';
import { UrlShortenerCollectionService } from '../../services/url-shortener-collection.service';
import { ExportToCsv } from 'export-to-csv';
import moment, { Moment } from 'moment';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DateFormat } from 'src/app/shared/helpers/DateFormat';
import { ListingComponent } from 'src/app/shared/abstracts/listing-component';
import { IRequestCount } from '../../interfaces/IRequestCount';
import { HttpParams } from '@angular/common/http';
import { MatTableDataSource } from '@angular/material/table';
import { tap } from 'rxjs';
import { A } from '@angular/cdk/keycodes';

interface Period {
  value: string;
  viewValue: string;
}

export enum ToggleEnumRange {
  Daily,
  Weekly,
  Monthly
}

@Component({
  selector: 'url-shortener-detail',
  templateUrl: './url-shortener-detail.component.html',
  styleUrls: ['./url-shortener-detail.component.scss'],
  providers: [
    {provide: MAT_DATE_FORMATS, useValue: DateFormat},
  ],
})
export class UrlShortenerDetailComponent extends ListingComponent<IRequestCount> implements OnInit {

  id: number
	urlInfo
	range = new FormGroup({
		startDate: new FormControl<Moment | null>(null),
		endDate: new FormControl<Moment | null>(null),
	})

  constructor(
    public readonly activatedRoute: ActivatedRoute,
    private readonly urlShortenerService: UrlShortenerCollectionService,
    public readonly mainRouter: Router
  ) {
		super();
  }
	populateList<U>(params?: U | HttpParams, urlHistory?: boolean): void {
		const startDate = moment(this.range.value.startDate)
		const endDate = moment(this.range.value.endDate)
		params = this.makeSearchParams({
			id: this.id,
			startDate: startDate.toISOString(),
      endDate: endDate.toISOString()
    })
		this.urlShortenerService.getClicks(params)
			.pipe(
				tap(d=>this.updateURLQuery(params as HttpParams, urlHistory))
			)
			.subscribe((res:any) => {
				if(res){
					this.dataSource = new MatTableDataSource<IRequestCount>(res?.sort((a,b) => {
						if(moment(a['date']).isAfter(moment(b['date']))) {
							return 1
						} else if(moment(a['date']).isSame(moment(b['date']))) {
							return 0
						}
						return -1
					}) );
					this.total = res.length;
				}
			});
	}
	getExtraListingParams(): void {
		throw new Error('Method not implemented.');
	}

	ngOnInit(): void {
    this.id = +this.activatedRoute.snapshot.paramMap.get('id');
    if(this.id){
      this.urlShortenerService.get(this.id)
				.subscribe(d=> this.urlInfo = d)
    }
		const startDateString = this.activatedRoute?.snapshot.queryParamMap.get('startDate') || null
		const endDateString = this.activatedRoute?.snapshot.queryParamMap.get('endDate') || null

		let endDate: Moment = moment(endDateString), startDate: Moment = moment(startDateString)
		if(!endDateString) {
			endDate = moment()
		}

		if(!startDateString || (startDate && startDate.isValid && startDate.isSameOrAfter(endDate))) {
			startDate = endDate.clone().add(-1, 'month')
		}

		this.range.patchValue({
			startDate: startDate.startOf('day'),
			endDate: endDate.endOf('day')
		}, {emitEvent: false})

		this.populateList(null, true)

		this.listingCols = [
      'date',
			'TotalClicks'
    ];
  }

  async export(){
    this.urlShortenerService.export(this.id)
    .subscribe((res:any) => {
      if(res){
       const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: '',
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: "Short URL Detail -" + this.id
      };
      const csvExporter = new ExportToCsv(options);
      csvExporter.generateCsv(res);
      }
    });
  }

  filterFutureDates = (d: any): boolean => {
		return moment(d).isSameOrBefore(new Date, 'day');
  };


  onFetchData() {
    if(this.range.value.startDate && this.range.value.endDate){
			this.populateList()
    }
  }
}
