

<div class="row mb-3">
  <div class="col-12 text-right">
    <button mat-raised-button color="primary" >Back</button>
  </div>
</div>


<div>
  <div class="row" >
    <div class="col-4 text-left">
      <mat-form-field  appearance="fill" class="col-12">
				<mat-date-range-input [formGroup]="range" [rangePicker]="pickerRange" [dateFilter]="filterFutureDates">
					<input matStartDate formControlName="startDate"  placeholder="Start date">
					<input matEndDate formControlName="endDate"  placeholder="End date" (dateChange)="onFetchData()">
				</mat-date-range-input>
				<mat-hint>DD MMM YYYY - DD MMM YYYY</mat-hint>
				<mat-datepicker-toggle matIconSuffix [for]="pickerRange"></mat-datepicker-toggle>
				<mat-date-range-picker #pickerRange></mat-date-range-picker>

				<mat-error *ngIf="range?.controls.startDate?.hasError('matStartDateInvalid')">Invalid start
						date</mat-error>
				<mat-error *ngIf="range?.controls.endDate?.hasError('matEndDateInvalid')">Invalid end
						date</mat-error>
				</mat-form-field>
    </div>
    <div class="col-4 text-right">
      <button mat-raised-button class="export-btn" color="primary" (click)="export()">Export</button>
    </div>
  </div>
  <div class="row">
    <div class="col-7">
      <mat-grid-list cols="1" rowHeight="50px">
        <mat-grid-tile>
          <div class="grid-tile-content"> <b>Client Name: </b>
            <span *ngIf="urlInfo?.tags?.length > 0">
              <span *ngFor="let e of urlInfo.tags">
                <span *ngIf="e.key === 'client'">
                    {{ e.value }}
                </span>
              </span>
            </span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
        <div class="grid-tile-content"> <b>Long URL: </b>
          <a href="{{ urlInfo?.redirectURL }}" target="_blank"> <span>{{ urlInfo?.redirectURL }}</span> </a>  </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="grid-tile-content"> <b>Campaign Name: </b>
            <span *ngIf="urlInfo?.tags?.length > 0">
              <span *ngFor="let e of urlInfo.tags">
                <span *ngIf="e.key === 'campaign'">
                    {{ e.value }}
                </span>
              </span>
            </span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
        	<div class="grid-tile-content"> <b>Short URL: </b> <a href="https://{{ urlInfo?.fullURL }}" target="_blank"> <span>https://{{ urlInfo?.fullURL }}</span> </a> </div>
        </mat-grid-tile>
        <mat-grid-tile>
        	<div class="grid-tile-content"> <b>Description: </b> {{urlInfo?.description}} </div>
        </mat-grid-tile>
        <mat-grid-tile></mat-grid-tile>

      </mat-grid-list>
    </div>
    <div class="col-5">
			<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="width: 100%">
				<ng-container matColumnDef="date">
					<th mat-header-cell *matHeaderCellDef> Date </th>
					<td mat-cell *matCellDef="let element">
						{{ element.date | date:'dd MMM yyyy, EE' }}
					</td>
				</ng-container>

				<ng-container matColumnDef="TotalClicks">
					<th mat-header-cell *matHeaderCellDef> Clicks </th>
					<td mat-cell *matCellDef="let element;">
						{{ element.TotalClicks }}
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="listingCols" class="p-2"></tr>
				<tr mat-row *matRowDef="let row; columns: listingCols;" class="p-2"></tr>
			</table>
			<div class="row mb-4">
				<div class="col-12">
					<mat-paginator [length]="total" [pageSize]="pageSize" [showFirstLastButtons]="true" [pageIndex]="page"
						(page)="changePage($event)">
					</mat-paginator>
				</div>
			</div>
		</div>
  </div>
